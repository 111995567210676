import React, { useState, useEffect } from 'react'
import SideNav from './SideNav'
import TopNav from './TopNav'
import { url } from './Url'
import { toast } from 'react-toastify';
import axios from 'axios'
// import { Link } from 'react-router-dom'
const Home = ({ updateAdmin, user, token }) => {
    // console.log(token);
    const [appointCount, setAppointCount] = useState([]);
    const getCoutDetails = async () => {
        await axios.get(`${url}/appointsCount?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setAppointCount(res.data.data)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getCoutDetails();
    }, []);
    return (
        <div className="page">
            <SideNav />
            <TopNav updateAdmin={updateAdmin} user={user} token={token} />
            <div className="page-wrapper" >
                <div className="container-xl">
                    <div className="row row-deck row-cards mt-2">
                        <h1>Hi-Tech Admin Portal</h1>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body ">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="subheader">Total Appointment</div>
                                            <div className="my-3">
                                                {appointCount?.map((value, i) => {
                                                    return (
                                                        <h1 key={i} className="result">{value.total_appoints_count}</h1>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <img src="./img/total.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="subheader" style={{ color: "#ffd400" }}>Pending Appointment</div>
                                            <div className="my-3">
                                                {appointCount.map((value, i) => {
                                                    return (

                                                        <h1 key={i} className="result">{value.total_pending_count}</h1>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <img src="./img/pending.png" alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="subheader" style={{ color: "#6eff00" }}>Active Appointment</div>
                                            <div className="my-3">
                                                {appointCount.map((value, i) => {
                                                    // console.log(value)
                                                    return (
                                                        <h1 key={i} className="result">{value.total_approve_count + value.total_rescheduled_count}</h1>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <img src="./img/active.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="subheader" style={{ color: "rgb(255 0 0)" }}>Cancel Appointment</div>
                                            <div className="my-3">
                                                {appointCount.map((value, i) => {
                                                    return (
                                                        <h1 key={i} className="result">{value.total_cancel_count}</h1>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <img src="./img/cancel.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Traffic summary</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Locations</h3>
                                    <div className="ratio ratio-21x9">
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home