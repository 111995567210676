import React, { useState, useEffect } from 'react'
import SideNav from '../SideNav'
import TopNav from '../TopNav'
import { url } from '../Url'
import axios from 'axios';
import { toast } from 'react-toastify';
import Pagination from '../Pagination'
import { Drawer, Box } from '@mui/material'
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const Department = ({ updateAdmin, user, token }) => {
    const [departmentSearch, setDepartmentSearch] = useState("");
    const showPerPage = 5;
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });
    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [deprtAdd, setDpartAdd] = useState(false);
    const [counter, setCounter] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const getDepartmentList = async () => {
        await axios.get(`${url}/getDepartmentDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.data.code === "200") {
                setDepartmentList(res.data.data);
            } else {
                toast.error(res.data.message, {
                    theme: "colored"
                })
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No department details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getDepartmentList();
    }, [counter])

    const changeDeprtStatus = async (val) => {
        let { departments_id, departments_status } = val;
        if (departments_status === 1) {
            departments_status = 0;
        } else {
            departments_status = 1;
        }
        await axios.post(`${url}/changedepartStatus?_format=json`, {
            "departments_id": departments_id, "departments_status": departments_status
        }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.data.code === "200") {
                setCounter((c) => c + 1);
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            } else {
                setCounter((c) => c + 1);
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }

    const [departmentName, setDepartmentName] = useState("")
    const [deptImage, setDeptImage] = useState(null);
    const [previewImg, setPreviewImg] = useState(null);
    const handleDeptImage = (e) => {
        setDeptImage(e.target.files[0])
        if (e.target.files && e.target.files[0]) {
            setPreviewImg(URL.createObjectURL(e.target.files[0]))
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('departments_name', departmentName);
        formData.append('departments_image', deptImage);
        await axios.post(`${url}/addDepartment?_format=json`, formData, {
            headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            // console.log(res)
            if (res.data.code === '200') {
                getDepartmentList();
                setDpartAdd();
                setDepartmentName("");
                setDeptImage(null);
                toast.success('Successfully Inserted', {
                    theme: 'colored',
                    position: "bottom-right",
                })
            } else {
                toast.success('Something Went Wrong', {
                    theme: 'colored',
                    position: "bottom-right",
                })
            }
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    const [deptEditOpen, setDeptEditOpen] = useState(false);
    const [id, setId] = useState("");
    const [deptName, setDeptName] = useState("");
    const [deptImg, setDeptImg] = useState("");

    useEffect(() => {
        setPreviewImg(null)
    }, [deptEditOpen])

    const deptEdit = (data) => {
        setDeptEditOpen(true);
        setId(data.departments_id);
        setDeptName(data.departments_name);
        setDeptImg(data.departments_image);
    }
    const handleDeptImageEdit = (e) => {
        setDeptImg(e.target.files[0])
        if (e.target.files && e.target.files[0]) {
            setPreviewImg(URL.createObjectURL(e.target.files[0]))
        }
    }
    const handleDeptEdit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('departments_id', id);
        formData.append('departments_name', deptName);
        formData.append('departments_image', deptImg);
        await axios.post(`${url}/editdepartments?_format=json`, formData, {
            headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            // console.log(res)
            if (res.data.code === "200") {
                setDeptEditOpen();
                getDepartmentList();
                toast.success(res.data.message, {
                    theme: 'colored',
                    position: "bottom-right",
                })
            } else {
                toast.error(res.data.message, {
                    theme: 'colored',
                    position: "bottom-right",
                })
            }
        }).catch((err) => {
            // console.log(err);
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    const [activeDeprt, setActiveDeprt] = useState("active");
    const handleActiveDeprt = (e) => {
        setActiveDeprt(e.target.value);
    };
    return (
        <div className="page">
            <SideNav />
            <TopNav updateAdmin={updateAdmin} user={user} token={token} />
            <div className="page-wrapper" >
                <div className="container-xl">
                    {/* <div className="page-header d-print-none">
                        <div className="row g-2 align-items-center">

                            <div className="col-12 col-md-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <div onClick={() => setDpartAdd(true)} className="btn btn-primary d-none d-sm-inline-block">
                                        <i className="fas fa-plus"></i> &nbsp;
                                        Add Department
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <h2>Department List</h2>
                    <div className="d-flex justify-content-end my-2">
                        <ToggleButtonGroup
                            color="primary"
                            value={activeDeprt}
                            exclusive
                            onChange={handleActiveDeprt}
                            aria-label="Platform"
                        >
                            <ToggleButton value="active">Active</ToggleButton>
                            <ToggleButton value="inactive">Inactive</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className="container my-3">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="city">Search By Department Name </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter Department Name"
                                            name="searchName"
                                            type="text"
                                            onChange={(e) => setDepartmentSearch(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        activeDeprt === "active" &&
                        <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                            <table className="table-striped table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Department Name</th>
                                        <th scope="col">Department Photo</th>
                                        <th scope="col">status</th>
                                        {/* <th scope="col">action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        departmentList.filter((item) => {
                                            if (departmentSearch === "") {
                                                return item;
                                            } else if (
                                                item.departments_name.toLocaleLowerCase().includes(departmentSearch.toLocaleLowerCase())
                                            ) {
                                                return item;
                                            }
                                            return false;
                                        }).filter((data) => data.departments_status === 1).slice(pagination.start, pagination.end).map((val, i) => {

                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1 + pagination.start}</td>
                                                    <td style={{ fontWeight: 'bold' }}>{val.departments_name}</td>
                                                    <td>
                                                        <span>
                                                            <img style={{ width: "200px" }} src={`${url}/getDepartmentImage?_format=json&image=` + val.departments_image} alt="" />
                                                        </span>
                                                    </td>
                                                    <td>{val.departments_status === 1 ? <i onClick={() => changeDeprtStatus(val)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i> : <i onClick={() => changeDeprtStatus(val)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>}</td>
                                                    {/* <td onClick={() => deptEdit(val)}><i style={{ color: "green" }} className="fas fa-edit"></i></td> */}
                                                </tr>
                                            )

                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="mx-auto">
                                <Pagination
                                    showPerPage={showPerPage}
                                    onPaginationChange={onPaginationChange}
                                    total={departmentList.length}
                                />
                            </div>
                        </div>
                    }
                    {
                        activeDeprt === "inactive" &&
                        <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                            <table className="table-striped table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Department Name</th>
                                        <th scope="col">Department Photo</th>
                                        <th scope="col">status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        departmentList.filter((data) => data.departments_status === 0).map((val, i) => {
                                            // console.log('https://hitechapi.redesk.in/api/v1/' + val.departments_image)
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{val.departments_name}</td>
                                                    <td>
                                                        <span>
                                                            <img style={{ width: "200px" }} src={`${url}/getDepartmentImage?_format=json&image=` + val.departments_image} alt="" />
                                                        </span>
                                                    </td>
                                                    <td>{val.departments_status === 1 ? <i onClick={() => changeDeprtStatus(val)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i> : <i onClick={() => changeDeprtStatus(val)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
            <Drawer anchor='right' className="modal-blur" open={deprtAdd} onClose={() => setDpartAdd()} >
                <Box width='40rem'>
                    <div className="modal-header">
                        <h5 className="modal-title">+ Add Department</h5>
                        <button onClick={() => setDpartAdd()} type="button" className="btn-close"></button>
                    </div>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Department Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Department Name" value={departmentName} onChange={(e) => setDepartmentName(e.target.value)} />
                                            {/* {errors.location_address && touched.location_address ? <p className="form-valid">{errors.location_address}</p> : null} */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Department Image</label>
                                            <input type="file" className="form-control" name="Upload Department Image" placeholder="Upload Department Image" accept="image/*" onChange={handleDeptImage} />
                                            {/* {errors.location_city_name && touched.location_city_name ? <p className="form-valid">{errors.location_city_name}</p> : null} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <img style={{ width: "50%" }} src={previewImg} alt="preview" />
                            </div>
                            <div className="modal-footer my-2">
                                <button onClick={() => setDpartAdd()} type="button" className="btn btn-button link-secondary mx-2">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary " >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Drawer>
            {/* Update Department */}
            <Drawer anchor="right" className="modal-blur" open={deptEditOpen} onClose={() => setDeptEditOpen()}>
                <Box width="40rem">
                    <div className="modal-header">
                        <h5 className="modal-title">Update Department</h5>
                        <button onClick={() => setDeptEditOpen()} type="button" className="btn-close"></button>
                    </div>
                    <div className="container">
                        <form onSubmit={handleDeptEdit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Department Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Department Name" value={deptName} onChange={(e) => setDeptName(e.target.value)} />
                                            {/* {errors.location_address && touched.location_address ? <p className="form-valid">{errors.location_address}</p> : null} */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Department Image</label>
                                            <input type="file" className="form-control" placeholder="Upload Department Image" accept="image/*" onChange={handleDeptImageEdit} />

                                            {/* {errors.location_city_name && touched.location_city_name ? <p className="form-valid">{errors.location_city_name}</p> : null} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <img style={{ width: "50%" }} src={previewImg === null ? `${url}/getDepartmentImage?_format=json&image=${deptImg}` : previewImg} alt="preview" />
                            </div>
                            <div className="modal-footer mt-4">
                                <button onClick={() => setDeptEditOpen()} type="button" className="btn btn-button link-secondary mx-2">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary " >
                                    Submit
                                </button>
                            </div>

                        </form>
                    </div>
                </Box>
            </Drawer>
        </div>

    )
}

export default Department