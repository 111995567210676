import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
const Pagination = ({ showPerPage, onPaginationChange, total }) => {
    const [counter, setCounter] = useState(1);
    const numberOfButtons = Math.ceil(total / showPerPage)
    //console.log(numberOfButtons)

    useEffect(() => {
        const value = showPerPage * counter;
        onPaginationChange(value - showPerPage, value);
    }, [counter])
    const onButtonClick = (type) => {
        if (type === "prev") {
            if (counter === 1) {
                setCounter(1)
            } else {
                setCounter(counter - 1)

            }
        }
        else if (type === "next") {
            if (numberOfButtons === counter) {
                setCounter(counter)
                toast.error("No Further Data Available", {
                    position: "bottom-right",
                    theme: "colored",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });
            } else {
                setCounter(counter + 1)
            }
        }
    }
    return (
        <nav aria-label="Page navigation example" className="d-flex justify-content-center my-3">
            <ul className="pagination">
                {
                    counter !== 1 &&
                    <li onClick={() => onButtonClick("prev")} className="page-item">
                        <Link className="page-link" to="." aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </Link>
                    </li>
                }
                {
                    new Array(numberOfButtons).fill("").map((el, index) => {
                        return (
                            <li onClick={() => setCounter(index + 1)} key={index + 1} className={`page-item first-number ${index + 1 === counter ? "active" : null}`}><Link className="page-link" to=".">{index + 1}<span className="sr-only">(current)</span></Link></li>
                        )
                    })
                }
                {
                    numberOfButtons !== counter &&
                    <li onClick={() => onButtonClick("next")} className="page-item">
                        <Link className="page-link" to="." aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </Link>
                    </li>
                }
            </ul>
        </nav>
    )
}

export default Pagination