import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

const SideNav = () => {
    const location = useLocation();
    const loc = location.pathname;
    const staffNav = loc === "/pcm" || loc === "/doctor" || loc === "/consultant" || loc === "/tele_caller" || loc === "/mis_operator";
    return (
        <aside className="navbar navbar-vertical navbar-expand-lg navbar-transparent">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <h1 className="navbar-brand navbar-brand-autodark" style={{ backgroundColor: "#ffff" }}>
                    <Link to="/">
                        <img src="./img/Hi-tech.png" width="110" style={{ height: "7rem" }} alt="Tabler" className="navbar-brand-image" />
                    </Link>
                </h1>
                <div className="collapse navbar-collapse" id="navbar-menu">
                    <ul className="navbar-nav pt-lg-3" style={{ backgroundColor: "#ffff" }}>
                        <li className="nav-item">
                            <Link className="nav-link" to="/">
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <polyline points="5 12 3 12 12 3 21 12 19 12" />
                                        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                                    </svg>
                                </span>
                                <span className="nav-link-title">
                                    Home
                                </span>
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <div className={staffNav ? "nav-link dropdown-toggle show" : "nav-link dropdown-toggle"} data-bs-toggle="dropdown"
                                data-bs-auto-close="false" role="button" aria-expanded={staffNav ? "true" : "false"}>
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
                                        <line x1="12" y1="12" x2="20" y2="7.5" />
                                        <line x1="12" y1="12" x2="12" y2="21" />
                                        <line x1="12" y1="12" x2="4" y2="7.5" />
                                        <line x1="16" y1="5.25" x2="8" y2="9.75" />
                                    </svg>
                                </span>
                                <span className="nav-link-title">
                                    Staff Management
                                </span>
                            </div>
                            <div className={staffNav ? "dropdown-menu show" : "dropdown-menu"} data-bs-popper={staffNav ? "static" : null}>
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <NavLink className="dropdown-item " to="/doctor">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            Doctor
                                        </NavLink>
                                        <NavLink className="dropdown-item" to="/pcm">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            PCM
                                        </NavLink>
                                        <NavLink className="dropdown-item" to="/consultant">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            Consultant
                                        </NavLink>
                                        <NavLink className="dropdown-item" to="/tele_caller">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            Tele Caller
                                        </NavLink>
                                        <NavLink className="dropdown-item" to="/mis_operator">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            MIS Operator
                                        </NavLink>
                                    </div>

                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <div className={loc === "/report" ? "nav-link dropdown-toggle show" : "nav-link dropdown-toggle"} data-bs-toggle="dropdown"
                                data-bs-auto-close="false" role="button" aria-expanded={loc === "/report" ? "false" : "true"}>
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
                                        <line x1="12" y1="12" x2="20" y2="7.5" />
                                        <line x1="12" y1="12" x2="12" y2="21" />
                                        <line x1="12" y1="12" x2="4" y2="7.5" />
                                        <line x1="16" y1="5.25" x2="8" y2="9.75" />
                                    </svg>
                                </span>
                                <span className="nav-link-title">
                                    Appoint Management
                                </span>
                            </div>
                            <div className={loc === "/report" ? "dropdown-menu show" : "dropdown-menu"}>
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <NavLink className="dropdown-item" to="/report">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            Reports
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <div className={loc === "/department" ? "nav-link dropdown-toggle show" : "nav-link dropdown-toggle"} data-bs-toggle="dropdown"
                                data-bs-auto-close="false" role="button" aria-expanded={loc === "/department" ? "false" : "true"}>
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
                                        <line x1="12" y1="12" x2="20" y2="7.5" />
                                        <line x1="12" y1="12" x2="12" y2="21" />
                                        <line x1="12" y1="12" x2="4" y2="7.5" />
                                        <line x1="16" y1="5.25" x2="8" y2="9.75" />
                                    </svg>
                                </span>
                                <span className="nav-link-title">
                                    Department Management
                                </span>
                            </div>
                            <div className={loc === "/department" ? "dropdown-menu show" : "dropdown-menu"}>
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <NavLink className="dropdown-item" to="/department">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            Department
                                        </NavLink>


                                    </div>

                                </div>
                            </div>
                            <li className="nav-item dropdown">
                                <div className={loc === "/location" ? "nav-link dropdown-toggle show" : "nav-link dropdown-toggle"} data-bs-toggle="dropdown"
                                    data-bs-auto-close="false" role="button" aria-expanded={loc === "/location" ? "false" : "true"}>
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24"
                                            strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
                                            <line x1="12" y1="12" x2="20" y2="7.5" />
                                            <line x1="12" y1="12" x2="12" y2="21" />
                                            <line x1="12" y1="12" x2="4" y2="7.5" />
                                            <line x1="16" y1="5.25" x2="8" y2="9.75" />
                                        </svg>
                                    </span>
                                    <span className="nav-link-title">
                                        Location Management
                                    </span>
                                </div>
                                <div className={loc === "/location" ? "dropdown-menu show" : "dropdown-menu"}>
                                    <div className="dropdown-menu-columns">
                                        <div className="dropdown-menu-column">
                                            <Link className="dropdown-item" to="/location">
                                                <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                                Location
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </li>
                        <li className="nav-item dropdown">
                            <div className={loc === "/version" ? "nav-link dropdown-toggle show" : "nav-link dropdown-toggle"} data-bs-toggle="dropdown"
                                data-bs-auto-close="false" role="button" aria-expanded={loc === "/version" ? "false" : "true"}>
                                <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
                                        <line x1="12" y1="12" x2="20" y2="7.5" />
                                        <line x1="12" y1="12" x2="12" y2="21" />
                                        <line x1="12" y1="12" x2="4" y2="7.5" />
                                        <line x1="16" y1="5.25" x2="8" y2="9.75" />
                                    </svg>
                                </span>
                                <span className="nav-link-title">
                                    Version Management
                                </span>
                            </div>
                            <div className={loc === "/version" ? "dropdown-menu show" : "dropdown-menu"}>
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <Link className="dropdown-item" to="/version">
                                            <span className="mx-2"><i className="fas fa-hand-point-right"></i></span>
                                            Version Control
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/report">
                                <span className="nav-link-icon d-md-none d-lg-inline-block">

                                <i className="fas fa-clipboard-user"></i>
                                </span>
                                <span className="nav-link-title">
                                    Appointment Reports
                                </span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default SideNav