import React from 'react'

const Loader = () => {
    return (
        <div style={{ position: "absolute", left: "50%", top: "50%" }}>
            <img src="./loader.gif" alt="loader" style={{ width: "30%" }} />
        </div>
    )
}

export default Loader