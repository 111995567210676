import React, { useState } from 'react'
import axios from 'axios'
import { url } from './Url'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify';
const Login = ({ updateAdmin, adminToken }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({
        username: '',
        password: '',
    })
    const handleInputs = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user, [name]: value
        })
    }
    const hitechLogin = (e) => {
        e.preventDefault();
        const { username, password } = user
        axios.post(`${url}/staffLogIn?_format=json`, {
            username, password
        })
            .then((res) => {
                // console.log(res)
                if (res.status === 200) {
                    toast.success("Login Successfull", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                    adminToken(res.data.token);
                    updateAdmin(res.data.user)
                    navigate('/')
                } else {
                    toast.error("Login Failed", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            });
    }
    const [checkPass, setCheckPass] = useState(false)
    const checkPassword = () => {
        setCheckPass(!checkPass)
    }
    return (
        <div className="page page-center" style={{ backgroundImage: "url('./img/hitechbanner.svg')", backgroundSize: "cover", boxShadow: "1px 3px 14px 1px" }}>

            <div className="container-tight py-4">
                <form className="card card-md"  style={{ boxShadow: "1px 3px 14px 1px" }}>
                    <div className="card-body">
                        <h2 className="card-title text-center mb-4">Login to your account</h2>
                        <div className="mb-3">
                            <label className="form-label" style={{ color: "#bf80ff" }}><b>Employee_ID</b></label>
                            <input type="email" className="form-control" placeholder="Employee_ID" autoComplete="off" name="username" onChange={handleInputs} value={user.username} />
                        </div>
                        <div className="mb-2">
                            <label className="form-label" style={{ color: "#bf80ff" }}>
                                <b>Password</b>
                            </label>
                            <div className="input-group input-group-flat">
                                <input type={checkPass ? "text" : "password"} className="form-control" placeholder="Password" autoComplete="off" name="password" onChange={handleInputs} value={user.password} />
                                <span onClick={checkPassword} className="input-group-text">
                                    {
                                        checkPass ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>
                                    }

                                </span>
                            </div>
                        </div>

                        <div className="form-footer">
                            <button onClick={hitechLogin} type="submit" className="btn btn-primary w-100" style={{ backgroundColor: "#bf80ff" }}>Log in</button>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <Link to=".">
                                    forget password 
                            </Link>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default Login