import React, { useState, useEffect } from 'react'
import SideNav from '../SideNav'
import TopNav from '../TopNav'
import { url } from '../Url'
import axios from 'axios'
import { Form, Formik, Field } from 'formik'
import { version } from '../schemas'
import { toast } from 'react-toastify';
import { Drawer, Box } from '@mui/material'
const Terms = ({ updateAdmin, user, token }) => {
    const [getVersion, setGetVersion] = useState([]);
    const getVersionLists = async () => {
        await axios.get(`${url}/getVersion?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            res.data.code === "200" ? setGetVersion(res.data.data) : setGetVersion([])
        }).catch((err) => {
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => { getVersionLists() }, [])
    const [open, setOpen] = useState(false);
    const [versionId, setVersionId] = useState("");
    const [versionCode, setVersionCode] = useState("");
    const [versionUpdate, setVersionUpdate] = useState("");
    const editVersion = (val) => {
        setOpen(true)
        setVersionId(val.version_id);
        setVersionCode(val.version_code);
        setVersionUpdate(val.version_force_update);
    }
    const savedValues = {
        version_id: versionId,
        version_code: versionCode,
        version_force_update: versionUpdate,
        version_update_by: user.users_id
    }
    const handleSubmit = async () => {
        await axios.post(`${url}/updateVersionDetails?_format=json`, savedValues, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            setOpen();
            getVersionLists();
            if (res.data.code === "200") {
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            } else {
                toast.error(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    return (
        <div className="page">
            <SideNav />
            <TopNav updateAdmin={updateAdmin} user={user} />
            <div className="page-wrapper" >
                <div className="container-xl my-3">
                    <h2>Version Control</h2>
                    <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                        <table className="table-striped table table-vcenter card-table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">version code</th>
                                    <th scope="col">created at</th>
                                    <th scope="col">updated at</th>
                                    <th scope="col">force update</th>
                                    <th scope="col">action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getVersion.map((val, i) => {
                                        // console.log(val);

                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{val.version_code}</td>
                                                <td>{val.version_created_at.split("T")[0].split('-').reverse().join("-")}</td>
                                                <td>{val.version_updated_at.split(" ")[0].split('-').reverse().join("-")}</td>
                                                <td>{val.version_force_update === 0 ? "Disable" : "Enable"}</td>
                                                <td onClick={() => editVersion(val)}><i style={{ color: "green" }} className="fas fa-edit"></i></td>
                                            </tr>

                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Drawer anchor="right" className="modal-blur" open={open} onClose={() => setOpen()}>
                <Box width="30rem">
                    <div className="modal-header">
                        <h5 className="modal-title"><span><i ClassName="fa-solid fa-pencil"></i></span> Update Version</h5>
                        <button onClick={() => setOpen()} type="button" className="btn-close"></button>
                    </div>
                    <div className="container">
                        <Formik
                            initialValues={savedValues}
                            initialTouched={{
                                field: true,
                            }}
                            validationSchema={version}
                            enableReinitialize
                            onSubmit={handleSubmit}
                        >
                            {({ errors, touched }) => {
                                // console.log(errors);
                                return (
                                    <Form>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Version Code <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="version_code" value={versionCode} onChange={(e) => setVersionCode(e.target.value)} />
                                                        {errors.version_code && touched.version_code ? <p className="form-valid">{errors.version_code}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Version Force Update <span className="text-danger">*</span></label>
                                                        <Field as="select" className="form-control" value={versionUpdate} onChange={(e) => setVersionUpdate(e.target.value)}>
                                                            <option value="">---Choose Version Force Update---</option>
                                                            <option value="0">Disable</option>
                                                            <option value="1">Enable</option>
                                                        </Field>
                                                        {errors.version_force_update && touched.version_force_update ? <p className="form-valid">{errors.version_force_update}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="modal-footer my-2">
                                                    <button onClick={() => setOpen()} type="button" className="btn btn-button link-secondary mx-2">
                                                        Cancel
                                                    </button>
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </div>
                </Box>
            </Drawer>
        </div >
    )
}

export default Terms