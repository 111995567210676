import React, { useState, useEffect } from 'react'
import SideNav from '../SideNav'
import TopNav from '../TopNav'
import { url } from '../Url'
import { Drawer, Box } from '@mui/material';
import axios from 'axios'
import { Form, Formik, Field, useFormik } from 'formik'
import { addLocation, editLocation } from '../schemas'
import { toast } from 'react-toastify';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const Location = ({ updateAdmin, user, token }) => {
    const [open, setOpen] = useState(false);
    const initialValues = {
        location_address: "",
        location_city_name: "",
        location_state_name: "",
        location_pin: ""
    }
    const { values, touched, errors, handleBlur, handleSubmit, handleChange } = useFormik({
        initialValues: initialValues,
        validationSchema: addLocation,
        onSubmit: (values, action) => {
            handleAdd(values);
            if (values) {
                action.resetForm();
            }
        },
    })
    const [id, setId] = useState("");
    const [stateName, setStateName] = useState("");
    const [cityName, setCityName] = useState("");
    const [address, setAddress] = useState("");
    const [pin, setPin] = useState("");
    const savedValues = {
        location_id: id,
        location_address: address,
        location_city_name: cityName,
        location_state_name: stateName,
        location_pin: pin
    }
    const [loader, setLoader] = useState(false);
    const handleAdd = (values) => {
        axios.post(`${url}/addLocationDetails?_format=json`, values, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((res) => {
                // console.log(res)
                if (res.status === 200) {
                    setOpen();
                    getLocationList();
                    toast.success("Address added successfully", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                } else {
                    toast.error("Somethoing went wrong", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                }
            }).catch((err) => {
                toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
                if (err.response.status === 422 || err.response.status === 401) {
                    updateAdmin({});
                }
            });
    }
    const [counter, setCounter] = useState(false);
    const [locations, setLocations] = useState([])
    const getLocationList = () => {
        axios.get(`${url}/getLocationList?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setLocations(res.data.data)
            } else {
                toast.error("No data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No location details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getLocationList()
    }, [counter]);

    const changeLocationStatus = async (val) => {
        let { location_id, location_status } = val;
        if (location_status === 1) {
            location_status = 0;
        } else {
            location_status = 1;
        }
        await axios.post(`${url}/updateStatusLocation?_format=json`, { "location_id": location_id, "users_id": user.users_id, "location_status": location_status }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.data.code === "200") {
                setCounter((c) => c + 1);
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            } else {
                setCounter((c) => c + 1);
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    const [locEditOpen, setLocEditOpen] = useState(false);

    const locEdit = (val) => {
        setLocEditOpen(true);
        setId(val.location_id);
        setStateName(val.location_state_name);
        setCityName(val.location_city_name);
        setAddress(val.location_address);
        setPin(val.location_pin);
    }
    const handleLocEdit = async () => {
        setLoader(true);
        await axios.post(`${url}/updateLocationDetails?_format=json`, savedValues, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res);
            if (res.data.code === "200") {
                setLoader();
                setLocEditOpen();
                getLocationList();
                toast.success("Successfully Location Updated", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            } else {
                setLoader();
                toast.error("Location Updated Failed", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            setLoader();
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    const [activeLocation, setActiveLocation] = useState("active");
    const handleActiveLocation = (e) => {
        setActiveLocation(e.target.value);
    };
    return (
        <div className="page">
            <SideNav />

            <TopNav updateAdmin={updateAdmin} user={user} token={token} />
            <div className="page-wrapper" >
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row g-2 align-items-center">
                            {/* <!-- Page title actions --> */}
                            <div className="col-12 col-md-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <div onClick={() => setOpen(true)} className="btn btn-primary d-none d-sm-inline-block">
                                        <i className="fas fa-plus"></i> &nbsp;
                                        Add Location
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Location List</h2>
                    <div className="d-flex justify-content-end my-2">
                        <ToggleButtonGroup
                            color="primary"
                            value={activeLocation}
                            exclusive
                            onChange={handleActiveLocation}
                            aria-label="Platform"
                        >
                            <ToggleButton value="active">Active</ToggleButton>
                            <ToggleButton value="inactive">Inactive</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {
                        activeLocation === "active" &&
                        <div style={{ backgroundColor: "white" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">State</th>
                                        <th scope="col">City</th>
                                        <th scope="col">Pin</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        locations.filter((item) => item.location_status === 1).map((val, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th scope="col">{i + 1}</th>
                                                    <th scope="col">{val.location_address}</th>
                                                    <th scope="col">{val.location_state_name}</th>
                                                    <th scope="col">{val.location_city_name}</th>
                                                    <th scope="col">{val.location_pin}</th>
                                                    <td>{val.location_status === 1 ? <i onClick={() => changeLocationStatus(val)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i> : <i onClick={() => changeLocationStatus(val)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>}</td>
                                                    <td onClick={() => locEdit(val)}><i style={{ color: "green" }} className="fas fa-edit"></i></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        activeLocation === "inactive" &&
                        <div style={{ backgroundColor: "white" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">State</th>
                                        <th scope="col">City</th>
                                        <th scope="col">Pin</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        locations.filter((item) => item.location_status === 0).map((val, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th scope="col">{i + 1}</th>
                                                    <th scope="col">{val.location_address}</th>
                                                    <th scope="col">{val.location_state_name}</th>
                                                    <th scope="col">{val.location_city_name}</th>
                                                    <th scope="col">{val.location_pin}</th>
                                                    <td>{val.location_status === 1 ? <i onClick={() => changeLocationStatus(val)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i> : <i onClick={() => changeLocationStatus(val)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
            <Drawer anchor='right' className="modal-blur" open={open} onClose={() => setOpen(false)} >
                <Box width='40rem' >
                    <div className="modal-header">
                        <h5 className="modal-title"><span><i ClassName="fa-solid fa-pencil"></i></span>+ Add Location</h5>
                        <button onClick={() => setOpen(false)} type="button" className="btn-close"></button>
                    </div>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Locality <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="location_address" placeholder="Enter Locality" value={values.location_address} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.location_address && touched.location_address ? <p className="form-valid">{errors.location_address}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">City Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="location_city_name" placeholder="Enter City Name" value={values.location_city_name} onBlur={handleBlur} onChange={handleChange} />
                                            {errors.location_city_name && touched.location_city_name ? <p className="form-valid">{errors.location_city_name}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">State Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="location_state_name" placeholder="Enter State Name" value={values.location_state_name} onBlur={handleBlur} onChange={handleChange} />
                                            {errors.location_state_name && touched.location_state_name ? <p className="form-valid">{errors.location_state_name}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Pin Code <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="location_pin" placeholder="Enter Pin Code" value={values.location_pin} onBlur={handleBlur} onChange={handleChange} />
                                            {errors.location_pin && touched.location_pin ? <p className="form-valid">{errors.location_pin}</p> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer my-2">
                                <button onClick={() => setOpen(false)} type="button" className="btn btn-button link-secondary mx-2">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary " >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Drawer>
            {/* Location Edit */}
            <Drawer anchor="right" className="modal-blur" open={locEditOpen} onClose={() => setLocEditOpen()}>
                <Box width="50rem">
                    <div className="modal-header">
                        <h5 className="modal-title"><span><i ClassName="fa-solid fa-pencil"></i></span>Update Location</h5>
                        <button onClick={() => setLocEditOpen()} type="button" className="btn-close"></button>
                    </div>
                    <div className="container">
                        <Formik
                            initialValues={savedValues}
                            initialTouched={{
                                field: true,
                            }}
                            validationSchema={editLocation}
                            enableReinitialize
                            onSubmit={handleLocEdit}
                        >
                            {({ errors, touched }) => {
                                // console.log(errors);
                                return (
                                    <Form>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Locality <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="location_address" placeholder="Enter Locality" value={address} onChange={(e) => setAddress(e.target.value)} onBlur={handleBlur} />
                                                        {errors?.location_address && touched.location_address ? <p className="form-valid">{errors?.location_address}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">City Name <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="location_city_name" placeholder="Enter City Name" value={cityName} onChange={(e) => setCityName(e.target.value)} onBlur={handleBlur} />
                                                        {errors?.location_city_name && touched.location_city_name ? <p className="form-valid">{errors?.location_city_name}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">State Name <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="location_state_name" placeholder="Enter State Name" value={stateName} onChange={(e) => setStateName(e.target.value)} onBlur={handleBlur} />
                                                        {errors?.location_state_name && touched.location_state_name ? <p className="form-valid">{errors?.location_state_name}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Pin Code <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="location_pin" placeholder="Enter Pin Code" value={pin} onChange={(e) => setPin(e.target.value)} onBlur={handleBlur} />
                                                        {errors?.location_pin && touched.location_pin ? <p className="form-valid">{errors?.location_pin}</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer my-2">
                                            <button onClick={() => setLocEditOpen()} type="button" className="btn btn-button link-secondary mx-2">
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary " >
                                                {loader ? "Please Wait..." : "Submit"}
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </div>
                </Box>
            </Drawer>
        </div>
    )
}

export default Location