import * as yep from "yup"
import moment from "moment"
const MobileRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const pinRegex = /^[1-9][0-9]{5}$/
const name = /^[A-Za-z\s]{1,}[/.,]{0,1}[A-Za-z\s]{0,}$/
const specialChar = /^(\d+\.)?(\d+\.)?(\*|\d+)$/
// let currentDate = new Date().toJSON().slice(0, 10);
// console.log(currentDate)
// const data = new Date().getFullYear() - 18
// console.log(data)
export const addDoctor = yep.object({
    users_no: yep.string().trim().required("User No Required"),
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name').required("Name required"),
    users_password: yep.string().trim().min(4, "Password should be at least 4 characters").required("Password required"),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50).required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_depart_id: yep.string().required("Department Required"),
    users_doctor_reg_no: yep.string().required("Registration no Required"),
    users_gender: yep.string().required("Gender Required"),
})
export const addPcm = yep.object({
    users_no: yep.string().trim().required("User No Required"),
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name').required("Name Required"),
    users_password: yep.string().trim().min(4, "Password should be at least 4 characters").required("Password Required"),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50, "Experience should not be more than 50 years").required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_depart_id: yep.string().required("Department Required"),
    users_gender: yep.string().required("Gender Required")
})
export const editPcm = yep.object({
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name').required("Name Required"),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50).required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_depart_id: yep.string().required("Department Required"),
    users_gender: yep.string().required("Gender Required")
})
export const editConsultant = yep.object({
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").required("Name Required").matches(name, 'Invalid Name'),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50).required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_gender: yep.string().required("Gender Required")
})
export const editDoctor = yep.object({
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name'),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50).required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_depart_id: yep.string().required("Department Required"),
    users_gender: yep.string().required("Gender Required")
})
export const addConsultant = yep.object({
    users_no: yep.string().trim().required("User No Required"),
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name').required("Name Required"),
    users_password: yep.string().trim().min(4, "Password should be at least 4 characters").required("Password Required"),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50).required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_gender: yep.string().required("Gender Required")
})

export const editTeleCaller = yep.object({
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").required("Name Required").matches(name, 'Invalid Name'),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50).required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_gender: yep.string().required("Gender Required")
})
export const addTeleCaller = yep.object({
    users_no: yep.string().trim().required("User No Required"),
    users_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").required("Name Required").matches(name, 'Invalid Name'),
    users_password: yep.string().trim().min(4, "Password should be at least 4 characters").required("Password Required"),
    users_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    users_pin: yep.string().trim().max(6, 'please enter valid pin').matches(pinRegex, 'please enter valid pin').required("Pin required"),
    users_state_name: yep.string().trim().required("State name required").matches(name, 'Invalid State Name'),
    users_city_name: yep.string().trim().required("City name required").matches(name, 'Invalid City Name'),
    users_dob: yep.date().test(
        "DOB",
        "Age should be more than 21 years",
        value => {
            return moment().diff(moment(value), 'years') >= 21;
        }).required("Dob required"),
    users_address: yep.string().trim().required("Address required"),
    users_doctor_exp: yep.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0).max(50).required("Doctor Experience required"),
    users_qualification: yep.string().trim().required("Qualification required"),
    users_gender: yep.string().required("Gender Required")
})
export const addLocation = yep.object({
    location_address: yep.string().trim().required("Locality required"),
    location_city_name: yep.string().trim().required("City Name required").matches(name, 'Invalid City Name'),
    location_state_name: yep.string().trim().required("State Name required").matches(name, 'Invalid State Name'),
    location_pin: yep.string().trim().matches(pinRegex, 'please enter valid pin').required("Pin Code required")
})

export const editLocation = yep.object({
    location_address: yep.string().trim().required("Locality required").matches(name, 'Invalid City Name'),
    location_city_name: yep.string().trim().required("City Name required").matches(name, 'Invalid City Name'),
    location_state_name: yep.string().trim().required("State Name required").matches(name, 'Invalid State Name'),
    location_pin: yep.string().trim().matches(pinRegex, 'please enter valid pin').required("Pin Code required")
})
export const version = yep.object({
    version_code: yep.string().trim().matches(specialChar, "Please enter a valid version code").required("Version code required"),
    version_force_update: yep.string().required("Force update required")
})