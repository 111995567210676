import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Drawer, Box } from '@mui/material'
import axios from 'axios'
import { toast } from 'react-toastify';
import { url } from './Url'
const TopNav = ({ updateAdmin, user, token }) => {
    const [open, setOpen] = useState(false);
    const [newPassword, setNewPassword] = useState("")
    const handlePassword = () => {
        setOpen(true);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post(`${url}/updatedPassword?_format=json`, {
            "users_id": user.users_id,
            "newpassword": newPassword
        }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            console.log(res);
            if (res.data.code === "200") {
                setOpen();
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
                updateAdmin({});
            } else {
                toast.error(res.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    return (
        <header className="navbar navbar-expand-md navbar-light d-print-none">
            <div className="container-xl">
                <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"><Link href="."></Link></h1>
                <div className="navbar-nav flex-row order-md-last">
                    <div className="nav-item d-none d-md-flex me-3">
                        <div className="btn-list"></div>
                    </div>
                    <div className="nav-item dropdown"><Link to="." className="nav-link d-flex lh-1 text-reset p-0"
                        data-bs-toggle="dropdown" aria-label="Open user menu"><span className="avatar avatar-sm"
                            style={{ backgroundImage: "url('./img/avatar.png')" }}></span>
                        <div className="d-none d-xl-block ps-2">
                            <div>{user && user.users_name}</div>
                            <div className="mt-1 small text-muted">Admin</div>
                        </div>
                    </Link>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow"><Link onClick={handlePassword} to='.' className="dropdown-item">Change Password</Link>
                            <div className="dropdown-divider"></div><Link onClick={() => updateAdmin({})} to="/"
                                className="dropdown-item">Logout</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer anchor="right" className="modal-blur" open={open} onClose={() => setOpen()}>
                <Box width="25rem">
                    <div className="modal-header">
                        <h5 className="modal-title"><span><i className="fa-solid fa-pencil"></i></span> Change Password</h5>
                        <button onClick={() => setOpen()} type="button" className="btn-close"></button>
                    </div>
                    <div className="card">
                        <div className="container">
                            <div className="col-md-12">
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <label className="form-label">Enter New Password</label>
                                        <input type="text" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                        <div className="modal-footer my-2">
                                            <button onClick={() => setOpen()} type="button" className="btn btn-button link-secondary mx-2">
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary " >Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </header>
    )
}

export default TopNav