import React, { useState, useEffect } from 'react';
import SideNav from '../SideNav'
import TopNav from '../TopNav'
import { url } from '../Url'
import { Drawer, Box } from '@mui/material';
import axios from 'axios'
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
const ReportManagement = ({ updateAdmin, user, token }) => {
    let currentDate = new Date().toJSON().slice(0, 10);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const [report, setReport] = useState({
        appoints_id: "",
        appoints_name: "",
        appoints_mobile: "",
        appoints_status: "",
        users_id: "",
        appoints_created_by: "",
        fromDate: `${currentDate}`,
        toDate: `${currentDate}`,
        departments_name: ""
    })
    const inputReport = (e) => {
        const { name, value } = e.target;
        setReport({
            ...report, [name]: value
        })
    }
    const [departmentList, setDepartmentList] = useState([]);
    const getDepartmentList = async () => {
        await axios.get(`${url}/getDepartmentDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.data.code === "200") {
                setDepartmentList(res.data.data);
            } else {
                toast.error(res.data.message, {
                    theme: "colored"
                })
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No department details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getDepartmentList();
    }, [])
    const [counter, setCounter] = useState(0);
    const [getReport, setGetReport] = useState([])
    const getReportDetails = async () => {
        await axios.post(`${url}/getReportDetails?_format=json`, {
            "filters": report,
            "pagination": {
                "status": true,
                "limit": 20,
                "offset": 0
            },
            "sortBy": {
                "arrange": "DESC",
                "status": true,
                "fieldName": "appoints_id"
            }
        }, {

            headers: {
                Authorization: 'Bearer ' + token
            },
        }).then((res) => {
            // console.log(res);
            if (res.data.code === "200") {
                setGetReport(res.data.data)
                if (res.data.data.length === 0) {
                    toast.error("No matching report found", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                }
            }
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getReportDetails();
    }, []);
    const [open, setOpen] = useState(false);
    const [department, setDepartment] = useState("");
    // const [doctorName, setDoctorName] = useState("");
    const [doctorContact, setDoctorContact] = useState("");
    const [doctorQualification, setDoctorQualification] = useState("");
    const [appointCreatedBy, setAppointCreatedBy] = useState("");
    const [userType, setUserType] = useState("");
    const [basicDetails, setBasicDetails] = useState("");
    const [relation, setRelation] = useState("");
    const [cityName, setCityName] = useState("");
    const [stateName, setStateName] = useState("");
    const [pin, setPin] = useState("");
    const [address, setAddress] = useState("");
    const handleReport = (val) => {
        setOpen(true);
        // console.log(val)
        setDepartment(val.departments_name);
        setDoctorContact(val.doctor_contact)
        setDoctorQualification(val.doctor_qualification);
        setAppointCreatedBy(val.appoints_created_by)
        setUserType(val.users_type)
        setBasicDetails(val.appoints_basic_details);
        setRelation(val.patients_user_relation);
        setCityName(val.appoints_city_name);
        setStateName(val.appoints_state_name);
        setPin(val.appoints_pin);
        setAddress(val.appoints_address);
    }
    const reset = () => {
        setReport({
            appoints_id: "",
            appoints_name: "",
            appoints_mobile: "",
            appoints_status: "",
            users_id: "",
            appoints_created_by: "",
            fromDate: `${currentDate}`,
            toDate: `${currentDate}`,
            departments_name: ""
        })
        getReportDetails();
    }
    //Doctor List
    const [getDoctors, seGetDoctors] = useState([]);
    const getDocList = () => {
        axios.get(`${url}/getDoctorDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                seGetDoctors(res.data.data);
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No doctor details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }

            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    };
    useEffect(() => {
        getDocList();
    }, []);
    //Consultant List
    const [getConsultant, setGetConsultant] = useState([]);
    const getConsultantList = () => {
        axios.get(`${url}/getConsultantDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setGetConsultant(res.data.data);
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No consultant details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getConsultantList()
    }, []);
    //Telecaller List
    const [getTeleCaller, setGetTeleCaller] = useState([]);
    const getTeleCallerDetails = () => {
        axios.get(`${url}/getTeleCallerDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setGetTeleCaller(res.data.data)
                // console.log(res.data.data.length)
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No Tele Callers details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getTeleCallerDetails()
    }, []);
    //Pcm List
    const [getPcm, seGetPcm] = useState([]);
    const getPcmList = () => {
        axios.get(`${url}/getPcmDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                seGetPcm(res.data.data)
                // console.log(res.data.data.length)
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No pcm details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getPcmList()
    }, []);
    //Mis Operator List
    const [getMisOperator, setGetMisOperator] = useState([]);
    const getMisOperatorList = () => {
        axios.get(`${url}/getMisDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setGetMisOperator(res.data.data)
                // console.log(res.data.data.length)
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No MIS Operator details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getMisOperatorList()
    }, []);
    const [exportUrl, setExportUrl] = useState("");
    const handleExport = async () => {
        axios.get(`http://192.168.0.163:3010/generateFile`).then((res) => {
            console.log(res)
            res.status === 200 ? setExportUrl(res.data.data[0].url) : setExportUrl("")
        }).catch((err) => {
            console.log(err);
        })
    }
    console.log(exportUrl)
    return (
        <div className="page">
            <SideNav />
            <TopNav updateAdmin={updateAdmin} user={user} token={token} />
            <div className="page-wrapper" >
                <div style={{ background: "#f0f2f8" }}>
                    <div className="container-xl">
                        <h2>Appointment Reports</h2>
                        <div className="row my-3">
                            <div className="col-md-3 mb-3">
                                <div >
                                    <label htmlFor="city">Search By Patient id </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter patient id"
                                            name="appoints_id"
                                            type="text"
                                            onChange={inputReport}
                                            value={report.appoints_id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By Patient name </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter patient name "
                                            name="appoints_name"
                                            type="text"
                                            onChange={inputReport}
                                            value={report.appoints_name}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By Patient mobile </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter patient mobile"
                                            name="appoints_mobile"
                                            type="text"
                                            onChange={inputReport}
                                            value={report.appoints_mobile}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By Patient status </label>
                                    <div className="input-group">
                                        <select name="appoints_status" className="form-control" onChange={inputReport} value={report.appoints_status}>
                                            <option value="">---Choose Status---</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Approve</option>
                                            <option value="3">Rescheduled</option>
                                            <option value="4">Cancel</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-3">
                                <div >
                                    <label htmlFor="city">Search By Departments name </label>
                                    <div className="input-group">
                                        <select name="departments_name" className="form-control" onChange={inputReport} value={report.departments_name}>
                                            <option value="">---Choose Department---</option>
                                            {
                                                departmentList.map((val, i) => {
                                                    // console.log(val)
                                                    return (
                                                        <option key={i} value={val.departments_id}>{val.departments_name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By Doctors name</label>
                                    <div className="input-group">
                                        <select name="users_id" className="form-control" onChange={inputReport} value={report.users_id}>
                                            <option value="">---Choose Doctor---</option>
                                            {
                                                getDoctors.map((val, i) => {
                                                    return (
                                                        report.departments_name === val.departments_name &&
                                                        <option key={i} value={val.users_id}>{val.users_name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By Consultant </label>
                                    <div className="input-group">
                                        <select name="appoints_created_by" className="form-control" onChange={inputReport} value={report.appoints_created_by}>
                                            <option value="">---Choose Consultant---</option>
                                            {
                                                getConsultant.map((val, i) => {
                                                    // console.log(val)
                                                    return (
                                                        <option key={i} value={val.users_id}>{val.users_name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By Telecaller </label>
                                    <div className="input-group">
                                        <select name="appoints_created_by" className="form-control" onChange={inputReport} value={report.appoints_created_by}>
                                            <option value="">---Choose Telecaller---</option>
                                            {
                                                getTeleCaller.map((val, i) => {
                                                    // console.log(val)
                                                    return (
                                                        <option key={i} value={val.users_id}>{val.users_name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By Pcm </label>
                                    <div className="input-group">
                                        <select name="appoints_created_by" className="form-control" onChange={inputReport} value={report.appoints_created_by}>
                                            <option value="">---Choose Pcm---</option>
                                            {
                                                getPcm.map((val, i) => {
                                                    // console.log(val)
                                                    return (
                                                        <option key={i} value={val.users_id}>{val.users_name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By MIS Operator </label>
                                    <div className="input-group">
                                        <select name="appoints_created_by" className="form-control" onChange={inputReport} value={report.appoints_created_by}>
                                            <option value="">---Choose MIS Operator---</option>
                                            {
                                                getMisOperator.map((val, i) => {
                                                    // console.log(val)     
                                                    return (
                                                        <option key={i} value={val.users_id}>{val.users_name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-3">
                                <div>
                                    <label htmlFor="city">Search By fromDate </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            name="fromDate"
                                            type="date"
                                            onChange={inputReport}
                                            value={report.fromDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div >
                                    <label htmlFor="city">Search By toDate </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            name="toDate"
                                            type="date"
                                            onChange={inputReport}
                                            value={report.toDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end my-3">
                                <button onClick={reset} type="reset" className="btn btn-light" data-toggle="collapse" aria-expanded="false">
                                    Reset            </button> &nbsp;
                                <button onClick={() => { handleExport(); handleOpen(); }} className="btn btn-success" data-toggle="collapse" aria-expanded="false">
                                    Export &nbsp;<i className="fas fa-download"></i></button> &nbsp;
                                <button type="submit" className="btn btn-primary" onClick={getReportDetails}>
                                    Search        </button>
                            </div>

                        </div>
                    </div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={modalOpen}
                        onClose={handleClose}
                        closeAfterTransition
                    >
                        <Fade in={modalOpen}>
                            <Box sx={style}>
                            <a href={exportUrl} download className="btn btn-success d-flex" data-toggle="collapse" aria-expanded="false">
                                    Download &nbsp;<i className="fas fa-download"></i></a>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
                {
                    getReport.length === 0 ? <div className="ml-3">No data found</div> :
                        <div className="card">
                            <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                                <table className="table-striped table table-vcenter card-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">reference no</th>
                                            <th scope="col">patient name</th>
                                            <th scope="col">patient age</th>
                                            <th scope="col">patient gender</th>
                                            <th scope="col">patient mobile</th>
                                            <th scope="col">patient doctor</th>
                                            <th scope="col">Appoint Created Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">visit date</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getReport?.map((val, i) => {
                                                // console.log(val)
                                                return (
                                                    <tr key={i}>
                                                        <th scope="row">{i + 1}</th>
                                                        <td>{val.appoints_reference}</td>
                                                        <td>{val.appoints_name}</td>
                                                        <td>{val.appoints_age}</td>
                                                        <td>{val.appoints_gender === "1" ? "Male" : val.appoints_gender === "2" ? "Female" : "Other"}</td>
                                                        <td>{val.appoints_mobile}</td>
                                                        <td>{val.doctor_name ? val.doctor_name : "Not Assign"}</td>
                                                        <td>{val.appoints_created_date.split('T')[0].split('-').reverse().join('/')}</td>
                                                        <td>{val.appoints_status === 1 ? "Pending" : val.appoints_status === 2 ? "Approved" : val.appoints_status === 3 ? "Reschedule" : "Cancel"}</td>
                                                        <td>{val.appoints_time.split('T')[0].split("-").reverse().join("/")}</td>
                                                        <td onClick={() => handleReport(val)}><i className="fas fa-eye"></i></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
            </div>
            <Drawer anchor="right" className="modal-blur" open={open} onClose={() => setOpen()}>
                <Box width='60rem'>
                    <div className="container my-3">
                        <button onClick={() => setOpen()} className="btn btn-primary my-2 d-flex justify-content-end">close</button>
                        <div className="row">
                            <div className="bootcards-list col-md-12">
                                <div className="panel panel-default">
                                    <div className="list-group">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Department Name:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{department ? department : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Doctor Contact No:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{doctorContact ? doctorContact : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Doctor Qualification:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{doctorQualification ? doctorQualification : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item" id="fb">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Appointment Created By:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{appointCreatedBy ? `${appointCreatedBy} (${userType === 1 ? "Patient" : userType === 2 ? "Doctor" : userType === 3 ? "Pcm" : userType === 4 ? "Consultant" : userType === 5 ? "Tele Caller" : userType === 6 ? "Admin" : userType === 7 ? "MIS Operator" : null})` : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item" id="fb">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Patient Address:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{address ? address : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Patient Relation:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{relation === 1 ? "father" : relation === 2 ? "mother" : relation === 3 ? "brother" : relation === 4 ? "sister" : relation === 5 ? "daughter" : relation === 6 ? "cousin" : relation === 7 ? "wife" : relation === 8 ? "husband" : relation === 9 ? "other" : relation === 10 ? "self" : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Patient Basic Detail:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{basicDetails ? basicDetails : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Patient City Name:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{cityName ? cityName : "N/A"} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Patient State Name:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{stateName ? stateName : "N/A"} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <h4 className="list-group-item-heading">Patient pin:</h4>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="list-group-item-text">{pin ? pin : "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </div >
    );
}

export default ReportManagement;
